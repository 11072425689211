import { inject, Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ProfileActions } from '@core/store/profile/profile.actions';
import { ProfileState } from '@core/store/profile/profile.state';
import { TriberState } from '@core/store/triber/triber.state';
import { INTERNAL_ROUTES } from '@data/constants/routes';
import { Store } from '@ngxs/store';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WizardService {
  private readonly store = inject(Store);
  private readonly triberLoaded$ = this.store.select(TriberState.loaded);
  private readonly router = inject(Router);

  private trigerKeep = '';

  constructor() {
    this.triberLoaded$.pipe(filter(Boolean)).subscribe({
      next: () => {
        const metadata = this.store.selectSnapshot(ProfileState.metadata);
        const isOmited = Boolean(metadata && 'isOmited' in metadata);
        if (!isOmited) return this.showWizard();
      },
    });
  }

  showWizard(): void {
    let url = '';
    let params: NavigationExtras = {};

    switch (this.trigerKeep) {
      case ETrigerKeep.wizardHireTalent:
        url = INTERNAL_ROUTES.APP_WIZARD_TALENT_TYPE;
        params = this.getParamsToRemoveReturnWizardButton();
        break;

      case ETrigerKeep.TALENT_PUBLISH_CHOOSE:
        url = INTERNAL_ROUTES.APP_WIZARD_TALENT_PUBLISH;
        params = this.getParamsToRemoveReturnWizardButton();
        break;

      case ETrigerKeep.wizardTalentPublishBDT:
        url = INTERNAL_ROUTES.APP_ACCOUNT_FORM_ABOUT_ME_RESUME_WITHOUT_PARAMS;
        params = {
          queryParams: {
            modalidad: 'bolsa-de-trabajo',
          },
        };
        break;

      case ETrigerKeep.wizardTalentPublishPI:
        url = INTERNAL_ROUTES.APP_ACCOUNT_FORM_ABOUT_ME_PUBLICATION_WITHOUT_PARAMS;
        params = {
          queryParams: {
            modalidad: 'profesional-independiente',
          },
        };
        break;

      case ETrigerKeep.wizardStop:
        return;

      case ETrigerKeep.wizardTalentType:
        url = INTERNAL_ROUTES.APP_WIZARD_TALENT_TYPE;
        params = this.getParamsToRemoveReturnWizardButton();
        break;

      default:
        url = INTERNAL_ROUTES.APP_WIZARD_WELCOME;
        break;
    }

    this.setTrigerKeep(ETrigerKeep.wizarDefault);
    this.router.navigate([url], params);
  }

  setTrigerKeep(trigerKeep: ETrigerKeep): void {
    this.trigerKeep = trigerKeep;
  }

  setOmit(): void {
    this.store.dispatch(new ProfileActions.SetOmitMetadataWizrd());
  }

  private getParamsToRemoveReturnWizardButton(): NavigationExtras {
    return {
      queryParams: {
        hasReturnButton: false,
      },
    };
  }
}

export enum ETrigerKeep {
  wizarDefault = '',
  wizardHireTalent = 'wizardHireTalent',
  wizardTalentPublishBDT = 'wizardTalentPublishBDT',
  wizardTalentPublishPI = 'wizardTalentPublishPI',
  wizardStop = 'wizardWatting',
  wizardTalentType = 'wizardTalentType',
  TALENT_PUBLISH_CHOOSE = 'TALENT_PUBLISH_CHOOSE',
}
